import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/vercel/path0/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p><em parentName="p">{`Metcon Week`}</em></p>
    <p>{`30:00 AMRAP:`}</p>
    <p>{`15/12-Calorie Assault Bike`}</p>
    <p>{`15-DB S2OH (35/20’s)(rx+ 50/35’s)`}</p>
    <p>{`15-V Ups`}</p>
    <p>{`15-Wall Balls (20/14`}{`#`}{`)(rx+ 30/20)`}</p>
    <p><strong parentName="p"><em parentName="strong">{`*`}{`Open participants: You have until 8:00pm tonight to submit your
scores.  Don’t forget!`}</em></strong></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      